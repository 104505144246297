/* Component Dependencies */
var messageResourceTemplate = require('templates/messageResource.hbs');
var AriesComponent = require('libs/aries-component');
var PubSub = require('libs/pub-sub');

PubSub.register(AriesComponent.extend({
  type: 'messageResource',
  template: {
    'messageResource': messageResourceTemplate
  }
}));
